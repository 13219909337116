import React, { useState, useEffect, useRef } from 'react';
import { API_ENDPOINTS, fetchFromApi } from '../config/api';
import { FaSpotify, FaBandcamp } from 'react-icons/fa';
import '../styles/Releases.css';

const Releases = () => {
  const [releases, setReleases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const spotifyRefs = useRef({});

  // Fetch releases from API
  useEffect(() => {
    const fetchReleases = async () => {
      try {
        setLoading(true);
        const data = await fetchFromApi(API_ENDPOINTS.releases, {
          method: 'GET',
          mode: 'cors'
        });

        if (!data) {
          console.log('No data received from API');
          setReleases([]);
          return;
        }

        const transformedReleases = data.map(release => ({
          id: release.id,
          title: release.title,
          artist: release.artist,
          releaseDate: release.release_date,
          label: release.label || 'Independent',
          genres: release.genres || [],
          tracklist: release.tracklist || [],
          links: release.links || {},
          artwork: release.artwork_url || '/images/releases/release-placeholder.png'
        }));

        setReleases(transformedReleases);
        setError(null);
      } catch (err) {
        console.error('Error fetching releases:', err);
        setError('Failed to load releases');
      } finally {
        setLoading(false);
      }
    };

    fetchReleases();
  }, []);

  // Handle Spotify messages
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== 'https://open.spotify.com') return;
      
      if (event.data && typeof event.data === 'object') {
        const { type, data } = event.data;
        
        if (type === 'playback_update' && event.source) {
          const iframe = event.source.frameElement;
          const spotifyUrl = Object.keys(spotifyRefs.current).find(
            url => spotifyRefs.current[url] === iframe
          );
          
          if (spotifyUrl && data && !data.isPaused) {
            console.log('Spotify playback update:', event.data);
            
            // Pause other Spotify players
            Object.entries(spotifyRefs.current).forEach(([url, ref]) => {
              if (url !== spotifyUrl && ref) {
                try {
                  ref.contentWindow.postMessage({ command: 'pause' }, '*');
                } catch (err) {
                  console.error('Error pausing Spotify:', err);
                }
              }
            });
          }
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  if (loading) {
    return (
      <section id="releases" className="releases-section">
        <div className="section-content">
          <h2>LATEST RELEASES</h2>
          <div className="loading-state">Loading releases...</div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section id="releases" className="releases-section">
        <div className="section-content">
          <h2>LATEST RELEASES</h2>
          <div className="error-state">{error}</div>
        </div>
      </section>
    );
  }

  return (
    <section id="releases" className="releases-section">
      <div className="section-content">
        <h2>LATEST RELEASES</h2>
        <div className="releases-grid">
          {releases.map(release => (
            <div key={release.id} className="release-card">
              <a 
                href={release.links.bandcamp} 
                target="_blank" 
                rel="noopener noreferrer"
                className="release-artwork"
              >
                <img
                  src={release.artwork}
                  alt={`${release.title} by ${release.artist}`}
                  onError={(e) => {
                    e.target.style.display = 'none';
                    e.target.parentElement.style.background = 'linear-gradient(45deg, #2a2a2a, #3a3a3a)';
                  }}
                />
              </a>
              <div className="release-info">
                <h3>{release.artist && release.title ? `${release.artist} - ${release.title}` : release.title}</h3>
                <p className="release-date">
                  {`TLL-${String(release.id).padStart(3, '0')} - ${new Date(release.releaseDate + 'T00:00:00').toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}`}
                </p>
                <div className="social-links">
                  {release.links.spotify && (
                    <a 
                      href={release.links.spotify} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="social-link spotify"
                      title="Listen on Spotify"
                    >
                      <FaSpotify />
                    </a>
                  )}
                  {release.links.bandcamp && (
                    <a 
                      href={release.links.bandcamp} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="social-link bandcamp"
                      title="Buy on Bandcamp"
                    >
                      <FaBandcamp />
                    </a>
                  )}
                  {release.links.soundcloud && (
                    <a href={release.links.soundcloud} target="_blank" rel="noopener noreferrer">
                      SoundCloud
                    </a>
                  )}
                </div>
                {release.links.spotify && (
                  <div className="spotify-player">
                    <iframe
                      ref={el => {
                        if (el) spotifyRefs.current[release.links.spotify] = el;
                      }}
                      style={{ borderRadius: "12px", marginTop: "1rem" }}
                      src={`${release.links.spotify.replace('open.spotify.com', 'open.spotify.com/embed')}`}
                      width="100%"
                      height="80"
                      frameBorder="0"
                      allowFullScreen=""
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Releases;
