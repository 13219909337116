import { useState } from 'react';
import { API_ENDPOINTS, fetchFromApi } from '../config/api';
import '../styles/Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    success: false,
    error: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ loading: true, success: false, error: null });

    try {
      const response = await fetchFromApi(API_ENDPOINTS.inquiries, {
        method: 'POST',
        body: JSON.stringify(formData)
      });

      setSubmitStatus({ loading: false, success: true, error: null });
      // Reset form
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });

      // Clear success message after 5 seconds
      setTimeout(() => {
        setSubmitStatus(prev => ({ ...prev, success: false }));
      }, 5000);

    } catch (error) {
      console.error('Error submitting inquiry:', error);
      setSubmitStatus({ 
        loading: false, 
        success: false, 
        error: error.message || 'Failed to submit inquiry. Please try again.'
      });
    }
  };

  return (
    <section className="contact-section" id="contact">
      <div className="contact-container">
        <h2>Contact</h2>
        <p className="contact-description">
          Have a question or interested in booking? We'd love to hear from you.
        </p>
        
        {submitStatus.success && (
          <div className="status-message success">
            Message sent successfully! We'll get back to you soon.
          </div>
        )}
        
        {submitStatus.error && (
          <div className="status-message error">
            {submitStatus.error}
          </div>
        )}

        <form className="contact-form" onSubmit={handleSubmit}>
          {/* Subject - Full Width */}
          <div className="form-group full-width">
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
              required
              maxLength="200"
              disabled={submitStatus.loading}
              autoComplete="off"
            />
          </div>

          {/* Contact Information */}
          <div className="form-group">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
              disabled={submitStatus.loading}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              required
              disabled={submitStatus.loading}
              autoComplete="off"
            />
          </div>

          {/* Message Body */}
          <div className="form-group full-width">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message"
              required
              rows="4"
              disabled={submitStatus.loading}
            />
          </div>

          <div className="form-group button-container">
            <button 
              type="submit" 
              className={`submit-button ${submitStatus.loading ? 'loading' : ''}`}
              disabled={submitStatus.loading}
            >
              {submitStatus.loading ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
