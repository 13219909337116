import React, { useState, useEffect } from 'react';
import { API_ENDPOINTS, fetchFromApi } from '../config/api';
import UpcomingEventCard from './UpcomingEventCard';
import '../styles/UpcomingEvents.css';

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const data = await fetchFromApi(`${API_ENDPOINTS.upcomingEvents}`, {
          method: 'GET',
          mode: 'cors'
        });
        
        if (!data || data.error) {
          console.error('Error fetching events:', data?.error);
          setError(data?.error || 'Failed to fetch events');
          return;
        }

        const currentDate = new Date();
        
        // Group events by event_name
        const eventsByName = data.reduce((groups, event) => {
          const name = event.event_name;
          if (!groups[name]) {
            groups[name] = [];
          }
          groups[name].push(event);
          return groups;
        }, {});

        // Create grouped events array
        const groupedEvents = Object.entries(eventsByName).map(([eventName, events]) => {
          const sortedEvents = events
            .sort((a, b) => new Date(a.event_start) - new Date(b.event_start))
            .filter(event => new Date(event.event_start) > currentDate)
            .slice(0, 3);  // Limit to 3 upcoming dates per event type

          if (sortedEvents.length > 0) {
            return {
              ...sortedEvents[0],
              upcoming_dates: sortedEvents
            };
          }
          return null;
        }).filter(Boolean); // Remove any null entries

        setEvents(groupedEvents);
        
      } catch (err) {
        console.error('Error in fetchEvents:', err);
        setError('Failed to fetch events');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) return <div className="loading-error">Loading events...</div>;
  if (error) return <div className="loading-error">{error}</div>;

  return (
    <section id="upcoming-events" className="upcoming-events">
      <div className="section-content">
        <h2>Upcoming Events</h2>
        <div className="events-grid">
          {events.map((event, index) => (
            <UpcomingEventCard 
              key={index}
              event={event}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvents;
