import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Link } from '@mui/material';
import { FaTwitch } from 'react-icons/fa';

const UpcomingEventCard = ({ event }) => {
  const eventDateTime = event.upcoming_dates.slice(0, 1).map((date, index) => {
    const eventDate = new Date(date.event_start);
    const eventEndDate = new Date(date.event_end);
    
    const formattedDate = eventDate.toLocaleString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });

    const formattedStartTime = eventDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });

    const formattedEndTime = eventEndDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
    
    const format = date.description || '';

    return (
      <Box 
        key={index}
        sx={{ 
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Typography 
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ 
            width: '100%',
            fontWeight: 'bold'
          }}
        >
          {formattedDate}
        </Typography>
        <Typography 
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ width: '100%' }}
        >
          {`${formattedStartTime} - ${formattedEndTime}`}
        </Typography>
      </Box>
    );
  });

  return (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
        backgroundColor: 'var(--dark-bg)',
        '& .MuiTypography-root': {
          color: 'rgba(255, 255, 255, 0.87)'
        },
        '& .MuiTypography-colorTextSecondary': {
          color: 'rgba(255, 255, 255, 0.6)'
        }
      }}
    >
      <CardMedia
        component="img"
        height="400"
        image={event.image_url}
        alt={event.event_name}
        sx={{ 
          objectFit: 'contain',
          backgroundColor: '#000',
          width: '100%'
        }}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h5" component="div" sx={{ mb: 2 }} align="center">
          {event.event_name === 'DJ Coffee Sessions' ? `${event.event_name} ${event.upcoming_dates[0].event_id} - ${event.upcoming_dates[0].description}` : event.event_name}
        </Typography>

        {/* Artists */}
        <Typography 
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ 
            mb: 3,
            fontWeight: 'bold',
            px: 2,
            wordBreak: 'break-word',
            width: '100%',
            maxWidth: '100%',
            overflowWrap: 'break-word'
          }}
        >
          {event.upcoming_dates[0].featured_artist_names.join(' • ')}
        </Typography>

        {/* DateTime */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', mb: 4 }}>
          {eventDateTime}
        </Box>

        {event.location && event.location.venue && (
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
            <Link
              href={event.location.website}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <Typography 
                variant="body1"
                color="text.primary"
                align="center"
                sx={{ 
                  width: '100%',
                  fontWeight: 'bold'
                }}
              >
                {event.location.venue}
              </Typography>
            </Link>
            {event.location.address && (
              <Typography 
                variant="body2"
                color="text.secondary"
                align="center"
              >
                {event.location.address}
              </Typography>
            )}
          </Box>
        )}

        {/* Action Links */}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 4 }}>
          {event.livestream_link && (
            <Link
              href={event.livestream_link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: '#bf94ff',
                textDecoration: 'none',
                padding: '8px 16px',
                borderRadius: '4px',
                border: '1px solid #bf94ff',
                transition: 'all 0.2s ease',
                '&:hover': {
                  color: '#000',
                  backgroundColor: '#bf94ff',
                  textDecoration: 'none',
                  border: '1px solid #bf94ff'
                }
              }}
            >
              <FaTwitch size={24} />
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1rem'
                }}
              >
                Watch Live
              </Typography>
            </Link>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default UpcomingEventCard;
