import '../styles/About.css'

const About = () => {
  return (
    <section id="about" className="about">
      <div className="section-content">
        <h2>ABOUT</h2>
        <div className="about-content">
          <p>
            Hidden in the heart of Chicago lies The Lost Loft, an intimate underground collaborative 
            dedicated to authentic house and techno music. We are a vibrant community of DJs, producers, 
            photographers, and visual artists who share a passion for creating unique and unforgettable 
            musical experiences. Bringing the underground sounds of Detroit, Chicago, and beyond to 
            tastefully crafted vinyl DJ sets and original music productions.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About
