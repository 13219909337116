import { useState, useEffect } from 'react'
import { API_ENDPOINTS, fetchFromApi } from '../config/api'
import ArtistCard from './ArtistCard'
import '../styles/Artists.css'

const Artists = () => {
  const [artists, setArtists] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        setLoading(true)
        const data = await fetchFromApi(API_ENDPOINTS.artists)
        console.log('Artists data:', data)
        setArtists(data)
        setError(null)
      } catch (err) {
        console.error('Error fetching artists:', err)
        setError('Failed to load artists')
      } finally {
        setLoading(false)
      }
    }

    fetchArtists()
  }, [])

  if (loading) {
    return (
      <section id="artists" className="artists">
        <div className="section-content">
          <h2>Artists</h2>
          <div className="loading-state">Loading artists...</div>
        </div>
      </section>
    )
  }

  if (error) {
    return (
      <section id="artists" className="artists">
        <div className="section-content">
          <h2>Artists</h2>
          <div className="error-state">{error}</div>
        </div>
      </section>
    )
  }

  return (
    <section id="artists" className="artists">
      <div className="section-content">
        <h2>Artists</h2>
        <div className="artists-grid">
          {artists.map((artist) => (
            <ArtistCard key={artist.id} artist={artist} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Artists
