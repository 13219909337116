import { useState } from 'react'
import { Link } from 'react-router-dom'
import '../styles/Navbar.css'

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId)
    if (element) {
      const navHeight = 80 // Height of the navbar
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - navHeight

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })
    }
    setIsMenuOpen(false) // Close menu after clicking
  }

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo" onClick={() => scrollToSection('hero')}>
          The Lost Loft
        </div>

        <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <span className={`bar ${isMenuOpen ? 'open' : ''}`}></span>
          <span className={`bar ${isMenuOpen ? 'open' : ''}`}></span>
          <span className={`bar ${isMenuOpen ? 'open' : ''}`}></span>
        </div>

        <ul className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <div className="nav-link" onClick={() => scrollToSection('hero')}>
              Home
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-link" onClick={() => scrollToSection('about')}>
              About
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-link" onClick={() => scrollToSection('artists')}>
              Artists
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-link" onClick={() => scrollToSection('upcoming-events')}>
              Events
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-link" onClick={() => scrollToSection('releases')}>
              Releases
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-link" onClick={() => scrollToSection('contact')}>
              Contact
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
